import { FunctionComponent, PropsWithChildren, useCallback } from 'react';
import Link from 'next/link';

export interface IFooterContentItemProps extends PropsWithChildren {
    href?: string;
    onClick?: () => void;
    target?: string;
}

const FooterContentItem: FunctionComponent<IFooterContentItemProps> = (props) => {
    const { target, href, children, onClick } = props;

    const scrollToTop = useCallback(() => {
        if (target === undefined) {
            setTimeout(() => {
                // It's not the best solution, but the state version wasn't working very well with renderings
                const el = window.document.getElementById('content-main') as HTMLDivElement;

                el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'start',
                });
            }, 250);
        }
    }, [target]);

    return (
        <div className="text-md text-gray-50 transition-all hover:text-neutral-100 hover:underline">
            {href && (
                <Link
                    href={href ?? ''}
                    target={target}
                    onClick={() => {
                        scrollToTop();
                    }}
                >
                    {children}
                </Link>
            )}
            {onClick && (
                <span
                    className="cursor-pointer"
                    onClick={() => {
                        scrollToTop();
                        onClick();
                    }}
                >
                    {children}
                </span>
            )}
        </div>
    );
};

export default FooterContentItem;
