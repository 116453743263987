import { atom } from 'recoil';
import { nanoid } from 'nanoid';

export interface IShopSearchState {
    needle: string;
    needleLocal: string;
    showSearch: boolean;
}

export const shopSearchStateRecoil = atom<IShopSearchState>({
    key: `shop-search-state/${nanoid()}`,
    default: {
        needle: '',
        needleLocal: '',
        showSearch: false,
    },
});
