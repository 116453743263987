import { atom } from 'recoil';
import { nanoid } from 'nanoid';

export interface IFavouriteState {
    showModal: boolean;
}

export const favouriteStateRecoil = atom<IFavouriteState>({
    key: `user-context-state/${nanoid()}`,
    default: {
        showModal: false,
    },
});
