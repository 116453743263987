export const isStorybook = () => {
    if (typeof window === 'undefined') return false;

    // If the system runs in Storybook, without a basePath /shop, return the basic-path instead
    const storyBookUrl = (process.env['NEXT_PUBLIC_STORYBOOK_URL'] as string) ?? null;

    const url = `${window.location.protocol}//${window.location.host}/`;

    return storyBookUrl && url.startsWith(storyBookUrl);
};

export const getBasePath = (): string => {
    if (isStorybook()) return '';

    return (process.env['NEXT_PUBLIC_BASE_PATH'] as string) ?? '';
};

/**
 * Linking: Cloud -> Legacy
 * @param path
 */
export const withBasePath = (path: string): string => {
    if (isStorybook()) return path;
    return (process.env['NEXT_PUBLIC_BASE_PATH'] as string) + path ?? '';
};

/**
 * Linking: Cloud -> Cloud
 * This function is a wrapper for possible coming changes in routing.
 * Atm it doesn't fulfill a real role, yet. But still use it now.
 * @param path
 */
export const withHostPath = (path: string): string => path ?? '';

/**
 * Linking: CLoud -> Legacy-Storefront (Domain Root)
 * This function is a wrapper for possible coming changes in routing.
 * Atm it doesn't fulfill a real role
 * @param path
 */
export const withHostPathAbsolute = (path: string): string => {
    return `${(process.env['NEXT_PUBLIC_ROOT_URL'] as string) ?? ''}${path}`;
};

export const withUbootPath = (path: string): string => {
    return `/legacy?uBootPage=${encodeURIComponent(
        Buffer.from(
            `${(process.env['NEXT_PUBLIC_SHOP_URL'] as string) ?? ''}${path}`,
            'utf-8'
        ).toString('base64')
    )}`;
};
