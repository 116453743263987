import { useRecoilState } from 'recoil';
import { navigationShopAtom } from '../recoil/shopNavigation';
import { userContextStateRecoil } from '../../UserContextMenu/recoil/userContextState';
import { iwiStateRecoil } from '../../IWI/recoil/iwiState';
import { shopSearchStateRecoil } from '../../SearchBar/recoil/shopSearch';
import { useCallback } from 'react';
import { favouriteStateRecoil } from '../../Favourites/recoil/favouriteState';

export const useNavigationHandler = () => {
    const [navState, setNavState] = useRecoilState(navigationShopAtom);
    const [userContextState, setUserContextState] = useRecoilState(userContextStateRecoil);
    const [iwiState, setIwiState] = useRecoilState(iwiStateRecoil);
    const [searchState, setSearchState] = useRecoilState(shopSearchStateRecoil);
    const [favouriteState, setFavouriteState] = useRecoilState(favouriteStateRecoil);

    const resetNavigation = useCallback(() => {
        setNavState({ ...navState, showShop: false });
        setUserContextState({ ...userContextState, showModal: false, view: 'default' });
        setIwiState({ ...iwiState, image: null, show: false });
        setSearchState({ ...searchState, needleLocal: '', needle: '' });
        setFavouriteState({ ...favouriteState, showModal: false });
    }, [
        favouriteState,
        iwiState,
        navState,
        searchState,
        setFavouriteState,
        setIwiState,
        setNavState,
        setSearchState,
        setUserContextState,
        userContextState,
    ]);

    return { resetNavigation };
};
