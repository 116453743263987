import { atom } from 'recoil';
import { nanoid } from 'nanoid';

export interface INavigationShopState {
    showShop: boolean;
    showTopics: boolean;
    tab: number;
}

export const navigationShopAtom = atom<INavigationShopState>({
    key: `create-post-state/${nanoid()}`,
    default: {
        showShop: false,
        showTopics: false,
        tab: 0,
    },
});
