import { atom } from 'recoil';
import { v4 } from 'uuid';

interface IMoxieState {
    show: boolean;
    type: 'Standard' | 'Transparent';
}

export const moxieStateRecoil = atom<IMoxieState>({
    key: `moxie-state/${v4()}`,
    default: {
        show: false,
        type: 'Standard',
    },
});
