import { atom } from 'recoil';
import { v4 } from 'uuid';

interface IIWIRecoilState {
    show: boolean;
    firstname: string;
    lastname: string;
    email: string;
    image: null | File;
}

export const iwiStateRecoil = atom<IIWIRecoilState>({
    key: `iwi-state/${v4()}`,
    default: {
        show: false,
        image: null,
        lastname: '',
        firstname: '',
        email: '',
    },
});
