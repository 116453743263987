import React, { FunctionComponent, PropsWithChildren, useState } from 'react';
import style from './Footer.module.css';
import FooterContentTitle from './FooterContentTitle';
import FooterContentItem from './FooterContentItem';
import ImageBase from '../../atoms/ImageBase/ImageBase';
import { useEvent } from 'react-use';
import { toast } from 'react-toastify';
import { Button } from 'src/components/atoms/Buttons/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import Link from 'next/link';
import { faMicrosoft } from '@fortawesome/free-brands-svg-icons/faMicrosoft';
import { faApple } from '@fortawesome/free-brands-svg-icons';
import { withHostPath, withHostPathAbsolute } from '../../../helper/getBasePath';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { userContextStateRecoil } from '../../UserContextMenu/recoil/userContextState';
import { iwiStateRecoil } from '../../IWI/recoil/iwiState';
import { moxieStateRecoil } from '../../legacy/MoxieManager/recoil/moxieState';
import { useNavigationHandler } from '../../Header/hooks/useNavigationHandler';
import { useAllLocales } from '../../../hooks/locale/useAllLocales';
import { contactFormStateRecoil } from '../../contactFormLegacy/state/contactFormState';

export interface IFooterProps extends PropsWithChildren {}

const Footer: FunctionComponent<IFooterProps> = () => {
    const [showContactForm, setShowContactForm] = useState(false);
    const [moxieState, setMoxieState] = useRecoilState(moxieStateRecoil);
    const setContactFormState = useSetRecoilState(contactFormStateRecoil);

    const { resetNavigation } = useNavigationHandler();
    const [userContextState, setUserContextState] = useRecoilState(userContextStateRecoil);
    const [iwiState, setIwiState] = useRecoilState(iwiStateRecoil);

    const locales = useAllLocales();

    useEvent('message', (event: MessageEvent) => {
        if (showContactForm) {
            if (event.data && event.data === 'contactclose') {
                toast.success('Anfrage wurde gesendet', { toastId: 'contactsuccess' });
                setShowContactForm(false);
            }
        }
    });

    // TODO Image Loading optimieren und widht/height setzen

    return (
        <>
            <div className="relative right-0 left-0 z-10 overflow-hidden bg-white pt-[240px] text-white antialiased">
                {/* Hilltop */}
                <div className="relative m-auto w-full">
                    <div
                        className="absolute top-[-19px] left-[-350px] z-10 block h-[180px] w-[2200px] rotate-[3deg] rounded-full"
                        style={{ backgroundColor: '#51595f', borderRadius: '50%' }}
                    />
                    <div className="absolute top-[-226px] left-[32px]">
                        <div className={style['Balloon']}>
                            <ImageBase
                                src="/remaxBalloon.svg"
                                width={120}
                                height={120}
                                objectFit="contain"
                                alt="Remax Baloon"
                            />
                        </div>
                        <div className="relative top-[84px]">
                            <ImageBase
                                id="cityFooter"
                                src="/cityFooter.svg"
                                width={552.7 / 1.5}
                                height={257.6 / 1.5}
                                alt="City"
                            />
                        </div>
                    </div>
                    <div className="relative z-20 m-auto min-h-[240px] bg-remaxGray p-[24px] pt-16">
                        <div className="relative z-0 mx-auto mr-auto mb-16 flex max-w-screen-xl items-end gap-2 tracking-wide text-white no-underline">
                            <ImageBase
                                src="/rmc_footer_logo.png"
                                width={1055 / 4}
                                height={233 / 4}
                                objectFit="contain"
                                alt="Rmc Logo"
                            />
                        </div>
                        {locales && (
                            <div className="mx-auto grid max-w-screen-xl grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
                                <div className="mb-10 flex flex-col lg:mb-0">
                                    <FooterContentTitle>
                                        {locales['LL_Header_Themen'] ?? 'Become the Best'}
                                    </FooterContentTitle>
                                    <FooterContentItem
                                        href={withHostPathAbsolute(
                                            'content.php?categoryCode=MyREMAXOffice'
                                        )}
                                    >
                                        {locales['LL_Themen_Sidebar_MyREMAXOffice'] ??
                                            'My RE/MAX Office'}
                                    </FooterContentItem>
                                    <FooterContentItem
                                        href={withHostPathAbsolute(
                                            'content.php?categoryCode=Objektvermarktung'
                                        )}
                                    >
                                        {locales['LL_Header_Themen_Objectvermarktung'] ??
                                            'Property Marketing'}
                                    </FooterContentItem>
                                    <FooterContentItem
                                        href={withHostPathAbsolute(
                                            'content.php?categoryCode=Eigenwerbung'
                                        )}
                                    >
                                        {locales['LL_Header_Themen_SelfPromotion'] ??
                                            'Self-promotion'}
                                    </FooterContentItem>
                                    <FooterContentItem
                                        href={withHostPathAbsolute(
                                            'content.php?categoryCode=FranchiseSale'
                                        )}
                                    >
                                        {locales['LL_AgentOnb_Kategorie_Navigation_Copy'] ??
                                            'Franchise Sales'}
                                    </FooterContentItem>
                                </div>
                                <div className="mb-10 flex flex-col lg:mb-0">
                                    <FooterContentTitle>
                                        {locales['LL_General_Produkte'] ?? 'Products'}
                                    </FooterContentTitle>

                                    <FooterContentItem href={withHostPath('/category/272')}>
                                        {locales['LL_Footer_Visitenkarten'] ?? 'Business cards'}
                                    </FooterContentItem>
                                    <FooterContentItem href={withHostPath('/category/43')}>
                                        {locales['LL_Footer_Schilder'] ?? 'Signs'}
                                    </FooterContentItem>
                                    <FooterContentItem href={withHostPath('/category/53')}>
                                        V-Shaped agent and For Sale signs
                                    </FooterContentItem>
                                    <FooterContentItem href={withHostPath('/category/44')}>
                                        {locales['LL_Footer_Flyer'] ?? 'Cards and flyers'}
                                    </FooterContentItem>
                                    <FooterContentItem href={withHostPath('/category/45')}>
                                        {locales['LL_Footer_Broschure'] ?? 'Brochures'}
                                    </FooterContentItem>
                                    <FooterContentItem href={withHostPath('/category/48')}>
                                        {locales['LL_Footer_Anzeige'] ?? 'Ads'}
                                    </FooterContentItem>
                                    <FooterContentItem href={withHostPath('/category/49')}>
                                        {locales['LL_Footer_Alle_Produkte'] ?? 'All products'}
                                    </FooterContentItem>
                                    <FooterContentItem
                                        href={withHostPathAbsolute(
                                            'content.php?categoryCode=flipbookdemo'
                                        )}
                                    >
                                        E-Book Demos
                                    </FooterContentItem>
                                </div>
                                <div className="mb-10 flex flex-col lg:mb-0">
                                    <FooterContentTitle>
                                        {locales['LL_General_Konto'] ?? 'Account'}
                                    </FooterContentTitle>
                                    <FooterContentItem href={withHostPathAbsolute('shop/profile')}>
                                        {locales['LL_General_BenutzerProfil'] ?? 'Profile'}
                                    </FooterContentItem>
                                    <FooterContentItem
                                        href={withHostPathAbsolute('shop/cart?section=forLater')}
                                    >
                                        {locales['LL_General_GespeicherteProdukte'] ?? 'Watch List'}
                                    </FooterContentItem>
                                    <FooterContentItem
                                        href={withHostPathAbsolute('shop/orderhistory')}
                                    >
                                        {locales['LL_General_MeineBestellungen'] ?? 'My orders'}
                                    </FooterContentItem>
                                    <FooterContentItem
                                        onClick={() => {
                                            setUserContextState({
                                                ...userContextState,
                                                showModal: false,
                                            });
                                            setIwiState({ ...iwiState, show: true });
                                        }}
                                    >
                                        {locales['LL_General_Bilderservice'] ??
                                            'Portrait image service'}
                                    </FooterContentItem>
                                    <FooterContentItem
                                        onClick={() => {
                                            resetNavigation();
                                            setMoxieState({ ...moxieState, show: true });
                                        }}
                                    >
                                        {locales['LL_General_MyMediaManager'] ?? 'My image library'}
                                    </FooterContentItem>
                                    <div className="block h-10"></div>

                                    <FooterContentItem
                                        href={withHostPathAbsolute(
                                            'content.php?categoryCode=Impressum'
                                        )}
                                    >
                                        {locales['LL_Footer_Impressum'] ?? 'Imprint'}
                                    </FooterContentItem>
                                    <FooterContentItem
                                        href={withHostPathAbsolute(
                                            'content.php?categoryCode=Datenschutz'
                                        )}
                                    >
                                        {locales['LL_Footer_Datenschutz'] ?? 'Privacy Statement'}
                                    </FooterContentItem>
                                    <FooterContentItem
                                        href={withHostPathAbsolute(
                                            'content.php?categoryCode=Widerrufsbelehrung'
                                        )}
                                    >
                                        {locales['LL_Footer_Haftungsausschluss'] ?? 'Disclaimer'}
                                    </FooterContentItem>
                                    <FooterContentItem
                                        href={withHostPathAbsolute('content.php?categoryCode=AGB')}
                                    >
                                        {locales['LL_Footer_AGB'] ?? 'GTC'}
                                    </FooterContentItem>
                                </div>
                                <div className="relative mb-10 flex flex-col lg:mb-0">
                                    {/* 3rd Column - Infos */}
                                    <section className="relative -top-24 flex flex-col gap-2 border-2 border-neutral-200 p-2">
                                        <h3 className="font-medium text-xl">
                                            {locales['LL_ServiceInfo_Headline'] ?? 'Products'}
                                        </h3>
                                        <p className="text-md text-white">
                                            {locales['LL_ServiceInfo_Copytext_1'] ?? ''}
                                        </p>

                                        <Button
                                            color="primary-light"
                                            addtionalCss="my-4"
                                            onClick={() => {
                                                setContactFormState((currVal) => {
                                                    return { ...currVal, show: true };
                                                });
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faPhone}
                                                className="mr-2 text-white"
                                            />
                                            <span>
                                                {locales['LL_General_Menue_Kontakt_Button_2'] ?? ''}
                                            </span>
                                        </Button>

                                        <p className="text-md text-white">
                                            {locales['LL_ServiceInfo_Copytext_2'] ?? ''}
                                        </p>

                                        <p className="text-md text-white">
                                            {locales['LL_ServiceInfo_Copytext_3'] ?? ''}
                                        </p>

                                        <div className="my-4 h-[1px] w-full bg-neutral-50"></div>

                                        <p className="text-md text-white">
                                            {locales['LL_Kontaktformular_Support'] ?? ''}
                                        </p>

                                        <Link
                                            href="https://my.anydesk.com/v2/api/v2/custom-clients/downloads/public/F6EXNEM5KFO9/AnyDesk.exe"
                                            target="anydesk"
                                            className="flex flex-row flex-nowrap pl-4 gap-2 rounded bg-primary p-2 font-medium text-white hover:bg-primary-light"
                                        >
                                            <FontAwesomeIcon
                                                icon={faMicrosoft}
                                                className="left-7 text-2xl"
                                            />
                                            AnyDesk Windows
                                        </Link>

                                        <Link
                                            href="https://my.anydesk.com/v2/api/v2/custom-clients/downloads/public/YWNEAYEYWXGI/AnyDesk_Custom_Client.dmg"
                                            target="anydesk"
                                            className="flex flex-row flex-nowrap pl-4 gap-2 rounded bg-secondary p-2 font-medium text-white hover:bg-secondary-light"
                                        >
                                            <FontAwesomeIcon
                                                icon={faApple}
                                                className="left-7 text-2xl"
                                            />
                                            AnyDesk MacOS
                                        </Link>
                                    </section>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Footer;
