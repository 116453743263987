import React, { FunctionComponent } from 'react';
import { ImageProps } from 'next/dist/client/legacy/image';
import Image from 'next/legacy/image';

export interface IImageBase extends ImageProps {
    ignoreBasePath?: boolean;
}

const ImageBase: FunctionComponent<IImageBase> = (props) => {
    let basePath = process.env['NEXT_PUBLIC_BASE_PATH'] ?? '';
    if (props.ignoreBasePath) basePath = '';

    const { ignoreBasePath, src } = props;

    // Get src, but remove trailing / to prevent // which causes an error.
    // <ImageBase should be treated like <Image with src syntax.
    let givenSrc = (src as string) ?? '';

    givenSrc = givenSrc.replace(/^\/{1,2}(.*)$/, '$1');

    // Fix, because ignoreBasePath would cause a next HTML validation error.
    const newProps = { ...props };
    delete newProps.ignoreBasePath;

    return (
        <Image
            {...newProps}
            src={`${basePath}${!ignoreBasePath ? '/' : ''}${givenSrc}`}
            alt="image"
        />
    );
};

export default ImageBase;
