import { atom } from 'recoil';
import { nanoid } from 'nanoid';

export interface IUserContextState {
    showModal: boolean;
    view: 'default' | 'languages';
}

export const userContextStateRecoil = atom<IUserContextState>({
    key: `user-context-state/${nanoid()}`,
    default: {
        showModal: false,
        view: 'default',
    },
});
