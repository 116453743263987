import { atom, selector } from 'recoil';

export interface IContactFormState {
    show: boolean;
}

export const contactFormStateRecoil = atom<IContactFormState>({
    key: `contact-form-state-state}`,
    default: { show: false },
});

export const getContactFormShow = selector<boolean>({
    key: `/get-contact-form-show`,
    get: ({ get }): boolean => {
        return get(contactFormStateRecoil).show;
    },
});
